import React, {Component} from 'react';

export default class Label extends Component {
    render() {
        return (

            <div>
                <span className="gray label-width">{this.props.field_name || "未知字段名"}</span>
                {
                    this.props.require ? <span className="Data-entry-require">*</span> : null
                }
                {
                    this.props.field_desc ? <label className="field-desc label-width">{this.props.field_desc}</label> : ''                    
                }               
                {   this.props.demo_img && (this.props.field_type == 14 || this.props.field_type == 2) ? 
                    <span className="demo-img field-desc" onClick={()=>{
                        React.$wx.previewImage({current:this.props.demo_img,urls:[this.props.demo_img]})
                    }}>【查看示例图】
                    </span> : '' }
            </div>			
        )
    }

}