import React, {Component} from 'react';
import {inject, observer} from "mobx-react/index"
import { Flex, Button, Modal,Toast,ListView, Carousel} from 'antd-mobile';

import '../../res/style/homePage.less'
import WxApi from "../../common/wxapi";
import Config from "../../config";
import SetNickname from "../../components/SetNickname";
import NewMsgDialog from '../../components/NewMsgDialog';

import img_mask_arrow from "../../res/img/ic_mask_arrow.png";
import icon_right_img from '../../res/img/right_arrow_blue.png'
import icon_create_enroll from '../../res/img/icon_create_enroll.png'
import icon_arrow_down from '../../res/img/arrow_down.png'
import clock_img from '../../res/img/ic_public_lock.png'
import check_select from '../../res/img/ic_check_selected.png'
const bannerImage = 'https://cdn-xcxcustom.weiyoubot.cn/20210705/b6049009536ef7dfd84b72c38281b7ae.png'
const defaultImg =  'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230613/7052e007942a1c1f16bc9fde16351652.png'
@inject("myEnrollStore", "enrollStore")
@observer
 class homePage extends Component{
     state = {
        roleInfo:{},
        authType:0,
        activeList:[],
        keyword:'',
        currPage:1,
        roleMinProgram:'none',
        shareFriend:'none',
        isWeixin:false,
        loadMore:false,
        showH5Url:'none',
        showPublicUrl:'none',
        deactivate:'',
        statusText: ['未开始', '进行中', '已截止'],
        shareImgUrl:'',
        sharePanel:'none',
        shareMediaPanel:'none',
        roleType:  {
            0:'未认证',
            1:'个人',
            2:'企业',
            3:'组织', 
            4:'公众号'
        },
        filterIndex:2, 
        showLink:false,     
        hasVipCard:false,
        focus_column_img:'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230515/1f9ee8c5d90ea9c5faaf2a05a948bd67.png',
        blur_column_img:'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230515/268992c06f7df852f15bf20cdfd90fd4.png',
        focus_row_img:'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230515/0d269a3a6557467e7ede744451482118.png',
        blur_row_img:'https://cdn-xcx-qunsou.weiyoubot.cn/material/20230515/54c40732361c31f4f1a1078326fd66ad.png',
        focusIndex: 2,
        isShow: false,
        menuList: [],
        currTabIndex: 'pub',
        userAvatar: ''
     };

     componentDidMount(){
        let isWeixin = WxApi.isWeixn()
        this.setState({
            isWeixin:isWeixin,
        })

        if(!Config.TOKEN && isWeixin){
			WxApi.jumpWxAuth()
        }

        WxApi.configApi()
        Toast.loading('加载中')
        let owner_unionid = ''

        let href = window.location.href.split('?')
        let data = href[0].split('/')
        if(data[data.length-1].length==28 ||data[data.length-1].length == 29 || data[data.length-1].indexOf('default_')!=-1){//浏览器链接地址参数
            owner_unionid = data[data.length-1]
        }else{ 
            owner_unionid = sessionStorage.getItem('owner_unionid') //本地缓存
        }

        if(!owner_unionid){
            Toast.hide()
            this.props.history.replace('/page-404')
        }

        this.setState({
            isWeixin:WxApi.isWeixn(),
            unionid:sessionStorage.getItem('unionid'),//登陆者unionid
            owner_unionid:owner_unionid,//活动发起者
        },()=>{
            sessionStorage.setItem('owner_unionid',owner_unionid)
            this.getDetail()
            if(Config.TOKEN)this.initCardList()           
        }) 

        window._hmt.push(['_trackPageview',  '/h/'+owner_unionid])
     };

     render(){
        let bannerPic = bannerImage
        if(this.state.roleInfo.banner && this.state.roleInfo.banner.indexOf('/images/bg') == -1){
            bannerPic = this.state.roleInfo.banner
        }else if(this.state.roleInfo.logo){
            bannerPic = this.state.roleInfo.logo
        }    
         return (
            <div className="cen-homepage">
                <div className='filter-img' style={{backgroundImage:'url('+(bannerPic)+')'}}></div>
                {
                    this.renderMinProgramRole() //小程序码弹窗
                }
                {
                    this.renderShareTips()//分享给好友
                }
                {
                    this.renderSharePanel() //底部操作面板
                }
                {
                    this.renderLink()//微信短链弹窗
                }
                {
                    this.getsUrlLink()
                }
                {
                    this.getsPublicLink()
                }
                {
                    this.renderWxCode() //右侧广告位
                }
                {
                    this.rendeMedioPanel() //多媒体
                }
                <SetNickname ref="nickDom" onRef={(ref)=>{ this.childNickname = ref}} onSuccess={()=>{this.subHomePage()}}></SetNickname>
                <div className="header-box">
                    {
                        this.state.roleInfo.banner ?<div className="img-filter" ></div>:''
                    }
                    {
                     this.renderImage(bannerPic) 
                    }
                    <div className='header-content'>   
                        <div className="flex-start-box">
                            <div className="user-avatar cursor" style={{backgroundImage:'url('+this.state.userAvatar+')'}} onClick={()=>{ 
                                React.$wx.previewImage({
                                    current: this.state.userAvatar, // 当前显示图片的http链接
                                    urls: [this.state.userAvatar] // 需要预览的图片http链接列表
                                })
                            }}>                                
                            </div>
                            <div className='right-box'>
                                <h4 className="nick-name">{WxApi.getDefaultName(this.state.roleInfo.name)}</h4>
                                {
                                    this.renderEditorBtn()
                                }
                            </div>
                        </div>
                        {
                           this.state.roleInfo.summary && this.state.roleInfo.auth_status == 2 ? <span className="header-text">{this.state.roleInfo.summary }</span>:null
                        }      
                        <ul className="user-info flex-start-box">
                            <li className="list-item" style={{paddingLeft:'0px', cursor: 'default'}}>
                                <h4 className="fans-num">{this.state.roleInfo.fans_count}</h4>
                                <span>粉丝</span>
                            </li>
                            {
                                this.state.roleInfo.is_owner?
                                <li className="list-item" onClick={()=>{
                                    sessionStorage.setItem('tabIndex',3)
                                    window._hmt.push(['_trackPageview',  '/manage/myEnroll'])
                                    this.props.history.push('/manage/myEnroll')
                                }}>
                                    <h4 className="fans-num">{this.state.roleInfo.sub_count || 0}</h4>
                                    <span>关注</span>
                                </li>:''
                            }
                            <li className="list-item">
                                {
                                    this.state.roleInfo.auth_status !=2?
                                    <div className='flex-column-center' style={{alignItems:'center'}} onClick={()=>{
                                        if(this.state.roleInfo.is_owner){
                                            React.$wx.jumpNewPath.call(this,'/auth')
                                        }
                                    }}>
                                        <span className="red-text">未认证</span>
                                    </div>
                                    :
                                    <div onClick={()=>{  
                                        let params = {
                                            unionid: this.state.owner_unionid,
                                            is_owner: this.state.roleInfo.is_owner
                                        }      
                                        sessionStorage.setItem('authMsg', JSON.stringify(params))                            
                                        this.props.history.push(`/auth-message`)
                                    }}>
                                        <i className="header-icon iconfont icon-renzheng"></i>
                                        <span>{this.state.roleType[this.state.roleInfo.auth_type] }</span>
                                    </div>
                                }
                            </li>
                            {
                                this.state.roleInfo.phone?
                                <li className='list-item'>
                                    <img className="meida-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20220105/7cbe5c028e43cb0361708e68a34a44ae.png"/>
                                    <a href={'tel:'+this.state.roleInfo.phone}>手机号</a>
                                </li>:''
                            }
                            {
                                this.renderMedia()
                            }
                        </ul>
                        {
                            this.state.roleInfo.contact && this.state.roleInfo.contact.length > this.state.filterIndex+1?
                            <div className='more-media-btn flex-center-box' onClick={()=>{
                                this.showMediaPanel('block')
                            }}>更多<img className='icon-arrow-down' src={icon_arrow_down}></img></div>:''
                        }
                    </div>
                </div>
                {
                    this.renderEnrollList() //公开活动列表
                }
                {
                   this.renderSetPublicBtn() 
                }
                {
                    // 消息订阅弹窗组件
                    <NewMsgDialog ref="messageDom" signName={this.state.roleInfo.name} owner_pic={this.state.roleInfo.logo} 
                    sub_user={this.state.roleInfo.sub_user} owner_unionid={this.state.owner_unionid} onRef={(ref)=>{ this.child = ref}}/> 
                }
               
            </div>
         )
     };

     renderEnrollList(){
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
          });
        const dataSource = ds.cloneWithRows(this.state.activeList.slice())
        
        const couponBtnStyle = {
            display:'block',
            width:'60px',
            height:"48px",
            position:'absolute',
            top: '-12px',
            left:'50%',
            transform:'translateX(-50%)'
        }     
        const miniBtnStyle = {
            width: '100%',
            height: '100%',
            position:'absolute',
            top: 0,
            left: 0,
            zIndex: '99',
        }  
        return (           
            <div className="box-actives">
                <div className="tab-menu">
                    <Carousel dots={false} autoplay={false} cellSpacing={10} slideWidth="90px" cellAlign="left">
                        {
                              this.state.menuList.map(item=>{
                                return (
                                    <div className={this.state.currTabIndex == item.key ? 'tab-item focus-text':'tab-item'} key={item.key} onClick={()=>{
                                        if(item.key == 'vip'){
                                            React.$wx.jumpNewPath.call(this,'/apply-vip')
                                        }else if(item.key == 'coupon' && !this.state.isWeixin){
                                            Toast.info('仅支持手机微信上点击领取哦')
                                        }else if(item.key == 'pub' || item.type == 'custom'){
                                            this.setState({
                                                currTabIndex: item.key,
                                                currPage: 1,
                                                activeList: [],
                                            },()=>{
                                                this.getEnrollData()
                                            })
                                        }
                                    }}>
                                        <span className="tab-text">{item.title}</span>
                                        {
                                            item.key == 'coupon' && this.state.isWeixin && WxApi.isMobile() ?
                                            <wx-open-launch-weapp id="coupon-btn" username="gh_ddc266cfe0c6" style={couponBtnStyle}  path={'/pages/coupon/enrollCoupon/couponList.html?unionid='+this.state.owner_unionid}> 
                                                <script type="text/wxtag-template">
                                                    <div style={couponBtnStyle}></div>
                                                </script>
                                            </wx-open-launch-weapp> : ''
                                        }
                                    </div>
                                )
                            })
                        }  
                    </Carousel>                 
                </div>
                <div className="flex-between-box">
                    {
                        this.state.activeList && this.state.activeList.length ?
                        <button className='btn-search' onClick={()=>{
                            this.props.history.push('/searchEnroll?type=homepage')
                        }}>
                            <i className='iconfont icon-sousuo1'></i>
                            <span>搜索</span>
                        </button> : null
                    }
                    {
                       this.state.activeList && this.state.activeList.length ?
                        <div className='operate-btn'>
                            <img className='icon-img' src={this.state.focusIndex == 1 ? this.state.focus_column_img : this.state.blur_column_img} onClick={()=>{
                                this.setState({focusIndex: 1});
                            }}></img>
                            <img className='icon-img' src={this.state.focusIndex == 2 ? this.state.focus_row_img : this.state.blur_row_img} onClick={()=>{
                                this.setState({focusIndex: 2});
                            }}></img>
                        </div> : null
                    }                       
                </div>
                <ListView
                    ref={el => this.lv = el}  
                    dataSource={dataSource}
                    renderFooter={() => (
                        <div style={{textAlign: 'center'}}>
                            {this.state.deactivate}
                        </div>)}
                    renderRow={this.state.focusIndex == 1 ? this.rowData : this.columnData}
                    useBodyScroll={true}
                    pageSize={10}
                    className={this.state.focusIndex == 2 ? 'container-panel' : ''}
                    scrollRenderAheadDistance={100}
                    onEndReachedThreshold={20}
                    onEndReached={()=>{this.refreshData()}}
                />
                {
                   this.state.isShow && !this.state.roleInfo.is_owner && (!this.state.activeList || !this.state.activeList.length )?
                    <div className='empty-enroll'>
                        <img className='empty-img' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20220304/a340f582ffc41ce33b7663779242273e.png' />
                        <span>暂无任何公开活动</span>
                    </div> : ''
                }
                {
                    this.state.isShow && this.state.roleInfo.is_owner && (!this.state.activeList || !this.state.activeList.length )?
                    <div className="open-enroll-tips" onClick={()=>{
                        if(!Config.TOKEN){
                            React.$wx.webLogin();
                        }else if(!this.state.isWeixin || !WxApi.isMobile()){
                            this.showRoleMinProgram('block');
                        }                        
                    }}>
                        <div className='flex-start-box'>
                            <img className='check-img' src={check_select}></img>
                            <h4 className='tips-title'>还未公开活动？试试主页更多玩法</h4>
                        </div>
                        <div className="warn-line">
                            <div>
                                <h3 className='warn-title'>设置公开活动</h3>
                                <span>活动上新、分类维护、便于分享</span>
                            </div>
                            <button className='open-btn'>去公开</button>
                        </div>
                        <div className="warn-line">
                            <div>
                                <h3 className='warn-title'>编辑主页资料</h3>
                                <span>修改头像昵称简介、背景和联系方式</span>
                            </div>
                            <button className='open-btn'>去编辑</button>
                        </div>
                        <div className="warn-line">
                           <div>
                                <h3 className='warn-title'>粉丝会员管理</h3>
                                <span>查看和管理粉丝、会员、黑名单</span>
                           </div>
                           <button className='open-btn'>去查看</button>
                        </div>
                        {
                            this.state.isWeixin && WxApi.isMobile() ? <wx-open-launch-weapp id="launch-btn" username="gh_ae6449501a82" style={miniBtnStyle}  path="/pages/homepage/homepage.html">  
                            <script type="text/wxtag-template">
                                <div style={miniBtnStyle}></div>
                            </script>
                            </wx-open-launch-weapp>:''
                        }
                    </div> : ''
                }
        </div>                          
        )
     };

     rowData = (item, sectionID, rowID)=>{
        let banner = item.banner ? item.banner : item.pic_url
         const bagStyle = {
            background:"url("+(banner||defaultImg)+") no-repeat center center",
            width:'100%',
            height:'0px',
            paddingBottom:'100%',
            WebkitBackgroundSize:'cover',
            MozBackgroundSize:'cover',
            backgroundSize:'cover',
        }
        
        return (
            <div className="active-list flex-start-box" onClick={()=>{
                let href = window.location.href
                let data = href.split('/')
                if(data[data.length-1].indexOf('default_')!=-1){
                    Toast.info('暂不支持跳转模板中心')
                    return
                }              
                if(item.sub_eid){
                    Config.EID = item.sub_eid
                }else{
                    Config.EID = item.eid
                }
                sessionStorage.setItem('eId',Config.EID)

                const unionid = sessionStorage.getItem('unionid')
                Config.referer = unionid||''
                sessionStorage.setItem('referer',Config.referer)

                Config.pathName = '/manage/myEnroll'    
                
                let jumpUrl = ''
                if(item.temp == 6){ //团购
                    window._hmt.push(['_trackPageview',  '/group']);
                    jumpUrl = '/group?eid='+ Config.EID+'&referer='+Config.referer
                }else{ //普通活动
                    window._hmt.push(['_trackPageview', '/detail']);
                    jumpUrl = '/detail?eid='+ Config.EID+'&referer='+Config.referer
                }
                React.$wx.jumpNewPath.call(this,jumpUrl)
            }}>
                <div className="active-img">
                    <div style={bagStyle}></div>
                    {item.top>0?<span className="top-button">置顶</span>:''}
                    {item.sub_eid?<span className="transfor-btn">转</span>:''}
                    {
                        item.hide == 1 ?
                        <div className='active-mask'>
                            <img className='clock-img' src={clock_img}></img>
                        </div> : ''
                    }
                </div>
                
                <div className="active-info">
                    <h3 className="active-title">{item.title}</h3>
                    <div className="active-num flex-between-box">
                        {
                            item.user_visible==0 || item.sub_eid ?<span ><i className="iconfont icon-renshu"></i>**/**</span>:
                            <span><i className="iconfont icon-renshu"></i>{item.count}{item.limit>0?'/'+item.limit:''}</span>
                        }
                        {
                            item.fee>0.5? <span>￥{(item.fee/100).toFixed(2)}</span>:''
                        }
                        {/* {
                            this.state.roleInfo.is_owner?
                            <span><i className="iconfont icon-quanjushezhi_o" onClick={()=>{
                                this.showRoleMinProgram('block')
                            }}></i>设置</span>:''
                        } */}
                        
                    </div>
                </div>
                <div className={item.status!=1?'active-end-status':'active-status'}>{this.state.statusText[item.status]}</div>
            </div>
        )
    }

    columnData = (item, sectionID, rowID)=>{
        let banner = item.banner ? item.banner : item.pic_url;
        return (
            <div className="enroll-list" onClick={()=>{
                let href = window.location.href
                let data = href.split('/')
                if(data[data.length-1].indexOf('default_')!=-1){
                    Toast.info('暂不支持跳转模板中心')
                    return
                }
                if(item.sub_eid){
                    Config.EID = item.sub_eid
                }else{
                    Config.EID = item.eid
                }                
                sessionStorage.setItem('eId',Config.EID)

                const unionid = sessionStorage.getItem('unionid')
                Config.referer = unionid||''
                sessionStorage.setItem('referer',Config.referer)

                Config.pathName = '/manage/myEnroll'    
                
                let jumpUrl = ''
                if(item.temp == 6){ //团购
                    window._hmt.push(['_trackPageview',  '/group']);
                    jumpUrl = '/group?eid='+ Config.EID+'&referer='+Config.referer
                }else{ //普通活动
                    window._hmt.push(['_trackPageview', '/detail']);
                    jumpUrl = '/detail?eid='+ Config.EID+'&referer='+Config.referer
                }
                React.$wx.jumpNewPath.call(this,jumpUrl)
            }}>
            <div className="enroll-img">
                <img className='banner-img' src={banner||defaultImg}></img>
                {item.top>0?<span className="top-button">置顶</span>:''}
                {item.sub_eid?<span className="transfor-btn">转</span>:''}
                {
                    item.hide == 1 ?
                    <div className='enroll-mask'>
                        <img className='clock-img' src={clock_img}></img>
                    </div> : ''
                }
            </div>
            
            <div className="enroll-info">
                <h3 className="enroll-title">{item.title}</h3>
                <div className="enroll-num flex-between-box">
                    {
                        item.user_visible==0 || item.sub_eid ?<span ><i className="iconfont icon-renshu"></i>**/**</span>:
                        <span className='flex-start-box'>
                            <i className="iconfont icon-renshu"></i>
                            <span>&nbsp;&nbsp;{item.count}{item.limit>0?'/'+item.limit:''}</span>
                        </span>
                    }
                    {
                        item.fee>0.5? <span>￥{(item.fee/100).toFixed(2)}</span>:''
                    }
                    <div className={item.status!=1?'active-end-status':'active-status'}>{this.state.statusText[item.status]}</div>
                </div>
            </div>
        </div>
        )
    }

    renderSetPublicBtn(){
        const miniBtnStyle = {
            width: '80%',
		    height:'60px',
		    background:'transparent',
            position:'fixed',
            bottom:'0px',
            right:'0px',
            zIndex:'999',
            boxSizing: 'content-box',
            paddingBottom: 'env(safe-area-inset-bottom)'
        }
        let btnMsg = ''
        if(this.state.roleInfo.is_owner){
            btnMsg = <div>
                        <div className="box-bottom flex-start-box">
                            <Button className="btn-editor share-btn" onClick={()=>{this.showSharePanel('block')}}><i></i>分享</Button>
                            <Button className="set-btn public-active" onClick={()=>{ this.showRoleMinProgram('block')}}>设置/取消公开</Button>
                            <Button className="set-btn" onClick={()=>{this.showRoleMinProgram('block')}}>粉丝/会员管理</Button>
                        </div>
                        {
                            this.state.isWeixin && WxApi.isMobile() ?<wx-open-launch-weapp id="launch-btn" username="gh_ae6449501a82" style={miniBtnStyle}  path="/pages/homepage/homepage.html">  
                            <script type="text/wxtag-template">
                                <div style={miniBtnStyle}></div>
                            </script>
                            </wx-open-launch-weapp>:''
                        }
                    </div> 
        }else{
            btnMsg = <div className="box-bottom flex-start-box">
                        <Button className="btn-editor share-btn" onClick={()=>{
                            if(!Config.TOKEN){
                                React.$wx.webLogin()
                            }else{
                                this.showSharePanel('block')
                            }
                        }}><i></i>分享</Button>
                        <Button className="set-btn" onClick={()=>{
                            if(!Config.TOKEN){
                                React.$wx.webLogin()
                            }else if(this.state.roleInfo.is_fans){
                                this.child.setDialog('open')
                            }else{
                                window.location.href = 'https://mp.weixin.qq.com/s/7xoT8N4jJuR36X3UoZEQVg'
                            }
                        }}><i className="iconfont icon-tixing-tianchong"></i>订阅通知</Button>
                    </div>
        }
        return btnMsg
    }
    renderImage(bannerPic){
        const bagStyle = {
            background:"url("+bannerPic+") no-repeat center center",
            width:'100%',
            height:'100%',
        }
        if(this.state.roleInfo.banner && this.state.roleInfo.banner.indexOf('/images/bg') == -1){     
            return <div style={bagStyle} className='bag-img'></div>
        }else if(this.state.roleInfo.logo){
            return <div style={bagStyle} className='logo-img'></div>
        }else{
            return <div style={bagStyle} className='bag-img'></div>
        }
      
    }
    // 编辑/关注按钮
    renderEditorBtn(){
        const miniBtnStyle = {
			width: '64px',
		    height:'40px',
		    background:'transparent',
            position:'absolute',
            top:'0px'
		}

        let msgDATA = ''
        if(this.state.roleInfo.is_owner){
                msgDATA = <div style={{position:'relative'}}>
                    <Button className="btn-editor" onClick={()=>{
                        this.showRoleMinProgram('block')
                    }}><i className="iconfont icon-bianji1"></i>编辑</Button>
                    {
                        this.state.isWeixin && WxApi.isMobile() ? 
                        <wx-open-launch-weapp id="launch-btn" username="gh_ae6449501a82" style={miniBtnStyle}  path="/pages/homepage/homepage.html"> 
                            <script type="text/wxtag-template">
                                <div style={miniBtnStyle}></div>
                            </script>
                        </wx-open-launch-weapp> : null
                    }                    
                </div>                                      
        }else {
            if(this.state.roleInfo.sub_user){  //已关注
                msgDATA = <Button className="btn-editor" onClick={()=>{
                    this.subHomePage()
                }}><i className="iconfont icon-yiguanzhu"></i></Button>
            }else{
                msgDATA = <Button className="btn-editor" onClick={async ()=>{
                    await this.childNickname.checkUserName() //判断是否有昵称
                    this.subHomePage()
                }}><i className="iconfont icon-jiahao"></i>关注</Button>
            }
        }
        
        return msgDATA
    }

    renderMedia(){
        if(!this.state.roleInfo.contact)return
        // <!-- 2公众号 3微博 4抖音 5微信 6哔哩哔哩 7知乎 8小红书 9豆瓣 10 github -->
            return (
                this.state.roleInfo.contact.map((item,index)=>{
                  return index <= this.state.filterIndex?(
                     <li className="list-item" key={index} onClick={()=>{
                        if(item.isJump){
                            window.open(item.value)
                        }else{
                            this.setState({
                                copyUrl:item.value
                            })
                            this.setH5Status('block','wx',item.name)
                        }
                    }}>
                        <img src={item.pic} className='meida-icon'></img>
                        <span>{item.name}</span>
                    </li>
                  ):''  
                })
                
            )
    }

    // 多媒体面板
    rendeMedioPanel(){
        const {contact,type} = this.state.roleInfo
        if(!contact)return
        return (
            <Flex className="Detail-share-choice-panel" style={{display:this.state.shareMediaPanel}}>
                <div className="share-choice-mask" onClick={() => { this.showMediaPanel('none')}}></div>
                <div className="share-choice-panel">
                    <div className="share-btn-panel" >
                    {
                         contact.map((item,index)=>{
                           return index > this.state.filterIndex?(
                                <div className="share-icon" key={item.id} style={{width:'20%'}} onClick={() => {
                                    this.showMediaPanel('none')
                                    if(item.isJump){
                                        window.open(item.value)
                                    }else{
                                        this.setState({
                                            copyUrl:item.value
                                        })
                                        this.setH5Status('block','wx','复制'+item.name)
                                    }
                                }}>
                                <img src={item.pic} className='share-img-icon' style={{width:'34px',height:'34px'}}></img>
                                    <div>{item.name}</div>
                                </div>
                           ):''
                        })
                    }
                    </div>
                    <div className="cancel-btn" onClick={() => {
						this.showMediaPanel('none')
					}}>取消</div>    
                </div>
            </Flex>
        )
    }


    showMediaPanel(type){
        this.setState({
            shareMediaPanel:type
        })
    }

     //查看小程序二维码
	renderMinProgramRole(){
		return(
            <Flex className="Detail-minProgram-panel" style={{display:this.state.roleMinProgram}}>
            <div className="share-choice-mask" onClick={() => { this.showRoleMinProgram('none')}}></div>
            <div className="minProgram-panel">
            <div className="new-share-minImg">
                <i className="close-btn" onClick={() => { this.showRoleMinProgram('none')}}></i>
                <div className="panel-title">请去「报名工具」小程序操作</div>
                <div className="QRCode-img">
                    <img src={this.state.shareImgUrl} />
                </div>
                <div className="min-tips">长按识别二维码，立即体验</div>
                </div>
            </div>
        </Flex>
		)
	}
    
    showRoleMinProgram(type){
        if(type == 'block'){
            React.$wx.openWeChat('/pages/homepage/homepage.html').then(()=>{
                this.setState({
                    roleMinProgram: 'block',
                })
            })            
        }else{
            this.setState({
                roleMinProgram: 'none',
            })
        }        
    }

    showFriend(type){
        this.setState({
	        shareFriend: type,
            sharePanel:'none',
	    })
    }

    // 分享好友
    renderShareTips(){
		return(
			<Flex className="Detail-share-tips-panel" style={{display:this.state.shareFriend}}>
				<div className="share-choice-mask" onClick={() => { this.showFriend('none')}}></div>
				<div className="tips-content" onClick={() => { this.showFriend('none')}}>
					<img className="arrow-share" src={img_mask_arrow} />
					<div className="share-text">
						<span>点击右上角“...”</span><br/>
						<span>选择“发送给朋友”或者“分享到朋友圈”</span><br/>
						<span>即可完成分享</span>
					</div>
				</div>
			</Flex>
		)
	}
    //分享选择面板
	renderSharePanel(is_show,detail) {
        return(
            <Flex className="Detail-share-choice-panel" style={{display:this.state.sharePanel}}>
                <div className="share-choice-mask" onClick={() => { this.showSharePanel('none')}}></div>
                <div className="share-choice-panel">
                    <div className="share-btn-panel">
                        <div className="share-icon home-page-items" onClick={() => { this.showFriend('block')}}>
                            <i></i>                           
                            <div>分享给好友</div>
                        </div> 
                        <div className="share-icon wx-img home-page-items" onClick={() => { this.getsShareImg()}}>
                            <i></i>                     
                            <div>生成小程序码</div>
                        </div>                       
                        <div className="share-icon mobile-img home-page-items" onClick={() => {
                                this.setState({
                                    copyUrl:`https://p.baominggongju.com/h/${this.state.owner_unionid}`
                                })
                                this.setH5Status('block','h5','个人主页链接')
                            }}>
                            <i></i>
                            <div>H5链接</div>
                        </div>                       
                        <div className="share-icon official-img home-page-items" onClick={() => {this.showPublicPanel('block')}}>
                            <i></i>     
                            <div>接入公众号</div>
                        </div>
                        {/* <div className="share-icon" onClick={() => {this.openLinkModel(detail)}}>
                            <i></i>
                            <div>分享微信短链</div>
	    				</div> */}
                    </div>   
                    <div className="cancel-btn" onClick={() => {
						this.showSharePanel('none')
					}}>取消</div>             
                </div>            
            </Flex>
        )
    }

    // 微信短链
	renderLink(){
		const minStyle = {		
			padding:'10px 15px',
			backgroundColor:'#F6F6F6',
			wordBreak:'break-all',
			fontSize:'14px',
			textAlign:'left'
		}
		return (
			<Modal
			visible={this.state.showLink}
			closable={true}
			transparent			
			onClose={()=>{this.setState({showLink:false})}}
			title="分享微信内短链"				
		 	>
			<div>
				<p style={minStyle}>#小程序://报名工具/2/kFroCKveSlz9aud</p>
				<Button type="primary" onClick={()=>{
					WxApi.copeText('22586')
					Toast.success('复制成功')
				}}>一键复制链接</Button>
			</div>
		  </Modal>
		)
	}

    showSharePanel(type){
		this.setState({
	        sharePanel: type,
	    })
	}

    openLinkModel(){
		this.setState({
			showLink:true,
			sharePanel:'none',
		})
	}

    showPublicPanel(type){
        this.setState({
            showPublicUrl:type,
            sharePanel:'none',
        })
    }

    setH5Status(type,copyType,copyTitle){
        this.setState({
            showH5Url:type,
            sharePanel:'none',
            copyType,
            copyTitle
        })
    }
    // 个人主页小程序码
    getsShareImg() {
        this.props.enrollStore.getHomePageShare(this.state.owner_unionid).then((d) => {
			if(d && d.url) {
                this.setState({ sharePanel:'none'})
                React.$wx.previewImage({
                    current: d.url, // 当前显示图片的http链接
                    urls: [d.url] // 需要预览的图片http链接列表
                });
			}
		});
    }

    // 个人主页h5链接 or 复制微信链接 
    getsUrlLink(){
       return (
        <Flex className="Detail-minProgram-panel" style={{display:this.state.showH5Url}}>
            <div className="share-choice-mask" onClick={() => { this.setH5Status('none')}}></div>
            <div className="minProgram-panel">
                <div className="new-share-minImg new-share-text">
                    <i className="close-btn" onClick={() => { this.setH5Status('none')}}></i>
                    <div className="panel-title">{this.state.copyTitle}</div>
                    <div className="QRCode-text">{this.state.copyUrl}</div>
                    {
                        this.state.copyType == 'h5'?<span className="text-tips">可将此链接放入公众号菜单、公众号文章、微博、抖音等平台，方便粉丝参与活动。</span>:''
                    }
                    <Button className='btn-copy' onClick={()=>{
                        WxApi.copeText(this.state.copyUrl)
                        Toast.success('复制成功')
                        this.setH5Status('none')
                    }}>一键复制</Button>
                </div>
            </div>
        </Flex>
       )
    }

    // 个人主页公众号
    getsPublicLink(){
        const data = {
            appId:"wxfaa08012777a431e",
            minPath:"/pages/homepage/homepage?unionid="+this.state.owner_unionid,
            oldId:'gh_ae6449501a82'
        }
        return (
         <Flex className="Detail-minProgram-panel" style={{display:this.state.showPublicUrl}}>
             <div className="share-choice-mask" onClick={() => { this.showPublicPanel('none')}}></div>
             <div className="minProgram-panel">
                 <div className="new-share-minImg new-share-public">
                     <i className="close-btn" onClick={() => { this.showPublicPanel('none')}}></i>
                     <h2 className="panel-title">接入公众号</h2>
                     <h3 className="path-title">AppID</h3>
                     <div className="copy-panel">
                        <div className="path-text">{data.appId}</div>
                        <button className="copyBtn" onClick={()=>{
                            WxApi.copeText(data.appId)
                            Toast.success('复制成功')
                        }}>复制</button>
                     </div>
                     <h3 className="path-title">小程序路径</h3>
                     <div className="copy-panel">
                        <div className="path-text">{data.minPath}</div>
                        <button className="copyBtn" onClick={()=>{
                            WxApi.copeText(data.minPath)
                            Toast.success('复制成功')
                        }}>复制</button>
                     </div>
                    <p className="notice-tips">以上即该活动的小程序路径，在公众号菜单和文章插入小程序时，填写上方路径即可正确跳转</p>
                    <h3 className="path-title">账号原始ID</h3>
                    <div className="copy-panel">
					    <div className="path-text">{data.oldId}</div>
					    <button className="copyBtn" onClick={()=>{
                            WxApi.copeText(data.oldId)
                            Toast.success('复制成功')
                        }}>复制</button>
                    </div>
				</div>
			
             </div>
         </Flex>
        )
     }

     //获取个人主页信息
     getDetail() {    
         this.props.myEnrollStore.getHomePageV2(this.state.owner_unionid).then(async res=>{
             if(res.sta == 0){
                 if(!res.data.name){
                     this.props.history.replace('/page-404')
                 }               
                 if(res.data.auth_type == 1 && !res.data.cert_show){
                    res.data.name = res.data.name.substr(0,1)+'**'
                 }
                 document.title = res.data.name
                 if(res.data.contact){
                    const leg = /(http|https):\/\/\S*/
                    res.data.contact = res.data.contact.filter(item=>{
                        item.isJump = leg.test(item.value)
                        if(item.type == 'wx_no' && !item.name){ //处理老数据，本身有微信号的，没头像和名称
                            item.name = '微信'
                            item.pic = 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220105/e484f5107c672410fae914d9d4e06bb3.png'
                        }
                        return item.type != 'phone'
                     })
                 }else{
                    res.data.contact = []
                 }
                 
                 if(res.data.is_owner){
                     this.state.filterIndex -= 1
                 }
                 if(res.data.phone){
                    this.state.filterIndex -=1
                 }
                 if(res.data.home_type){
                    res.data.home_type = res.data.home_type.filter(item=>item.hide != 1);
                 }

                 let userAvatar = await WxApi.checkImage(res.data.logo);               
                 if(!userAvatar){
                    userAvatar = Config.defaultAvatar;
                 }
                 this.setState({
                    userAvatar,
                    roleInfo:res.data,
                    menuList: res.data.home_type||[],
                    filterIndex:this.state.filterIndex
                 })
                 this.getTeamData().then(()=>{
                    this.getEnrollData()
                 })                
                 
                let _url = WxApi.replaceParamVal('referer',this.state.owner_unionid);
                let desc = res.data.summary?res.data.summary:'可以关注我，接受消息提醒'
                WxApi.setShare(res.data.name+'的个人主页',desc, _url,res.data.logo); 

                if(!WxApi.isMobile()){
                    this.getHomepageCode()
                }
             }
             setTimeout(()=>{
                Toast.hide()
             },1000)
         })
    };

    //获取会员卡列表
    initCardList(){
        this.props.myEnrollStore.vipCardList(this.state.owner_unionid).then(res=>{           
            if(res.sta == 0 && res.data && res.data.length > 0){
                this.setState({hasVipCard: true})                            
            }
        })
    } 

    // 右侧广告位
	renderWxCode(){
		if(!WxApi.isMobile()){
			return (
				<div className="advert-box-right">
                    <div className="wx-code-box">
                        <div className="wx-qrcode">
                            <img src={this.state.shareImgUrl}></img>
                        </div>
                        <span>微信扫一扫</span>
                        <span className="wx-watch">打开小程序</span>
					</div>
					<a className="create-enroll-box" target="_blank" href="https://www.baominggongju.com/?hmsr=h5">
						<img src={icon_create_enroll} className="icon-create"/>
						<span className="enroll-text">我也要创建活动</span>	
						<img src={icon_right_img} className="icon-right-blue"/>
					</a>
				</div>
			)
		}
	}

    // 个人主页二维码
    getHomepageCode(){
        this.props.enrollStore.getHomePageShare(this.state.owner_unionid).then((d) => {
			if(d && d.url) {
				this.setState({
					shareImgUrl: d.url,
				})
			}
		});
    }
    // 获取共创团队列表
    getTeamData(){
        return new Promise((resolve)=>{
            const params = {
                page: 1,
                count: 100,
                unionid: this.state.owner_unionid,
            }
            this.props.myEnrollStore.getTeamUser(params).then(async res=>{               
                if(res.sta == 0){         
                    let list = res.data.list;
                    for(let a = 0; a < list.length; a++){
                        list[a].banner = await WxApi.checkImage(list[a].banner);                      
                    }        
                    this.setState({ activeList: list });                   	
                    resolve();
                }else{
                    resolve()
                }
            })
        })        
    }
    // 获取公开活动列表
    getEnrollData(){
        const params = {
            type:6,
            count:10,
            keyword:this.state.keyword,
            page:this.state.currPage,
            unionid:this.state.owner_unionid,
            home_type: this.state.currTabIndex,
        }
        this.props.myEnrollStore.getEnrollList(params).then(async res=>{
            if(res.sta == 0){
                let loadMore = false
                let deactivate = '正在加载中...'
                if(res.data.items && res.data.items.length == 10){
                    loadMore = true
                }else{
                    deactivate = ''
                }
                res.data.items = res.data.items ? res.data.items : res.data
                if(!this.state.roleInfo.is_owner){ //非发起人则隐藏私密活动
                    res.data.items = res.data.items.filter(item=>item.hide != 1)
                }              
                let data = this.state.activeList.concat(res.data.items) 
                for(let a = 0; a < data.length; a++){
                    data[a].banner = await WxApi.checkImage(data[a].banner)             
                }                           
                this.setState({
                    activeList:data,
                    deactivate,
                    loadMore,
                    isShow: true,                  
                })
            }
        })
    };

    // 下拉加载更多
    refreshData(){
        if(this.state.loadMore){
            this.setState({
                refreshing:true,
                currPage:this.state.currPage+1,
            },()=>{
                this.getEnrollData()
            })
        }
    }
    // 关注
    subHomePage(){
        if(!Config.TOKEN){ //因为主页是免登录的，所以需要判断是否登录
            React.$wx.webLogin()
        }else if(this.state.roleInfo.sub_user == 1){
            Modal.alert('温馨提示','是否取消关注',[
                {text: '取消'},
                {text: '确定', style: 'default',onPress:()=>{
                    this.handleData()
                }},
            ])
        }else{
            this.handleData()
        }  
	}

    handleData(){
        let type = this.state.roleInfo.sub_user?0:1
		this.props.enrollStore.subscribeHomepage(type, this.state.owner_unionid).then((d) => {
			let label = '取消关注';
			if (type) label = '关注';
			if(d.sta == 0){
				Toast.success(label + '成功', 2);
                this.state.roleInfo.sub_user = type
				this.setState({
					roleInfo: this.state.roleInfo,
				})
			}else if(d.errcode == -7252){
                Modal.alert('温馨提示',d.msg+'。编号：'+d.data.black_no, [
                    { text: '取消', style: 'default'},
                    { text: '复制编号并留言', onPress: () => {	
                        WxApi.copeText(d.data.black_no);
                        Toast.info('复制成功');
                        setTimeout(()=>{
                            this.props.history.replace('/message')
                        },1000);
                    } },
                ])
            }else{
                Toast.fail(label + '失败', 2);
			}
		});
    }
 }


 export default homePage