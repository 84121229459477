// 个人中心
import React, {Component} from 'react'
import { Flex,Button,Modal,Toast } from 'antd-mobile'
import {inject, observer} from "mobx-react/index"
import '../../res/style/userCenter.less'
import WxApi from "../../common/wxapi"
import Config from "../../config"

import icon_settiing from '../../res/img/customized/ic_me_messagesetting.png'
import icon_record from '../../res/img/customized/ic_me_pay_record.png'
import right_arrow from '../../res/img/right_arrow.png'
import icon_auth from '../../res/img/customized/ic_person_identity_V.png'
import icon_identity_going from "../../res/img/customized/ic_person_identity_ongoing.png"
import icon_identity_no from '../../res/img/customized/ic_person_identity_no.png'
import icon_safe from '../../res/img/customized/ic_me_safe.png'

@inject("myEnrollStore", "enrollStore")
@observer
class userCenter extends Component{
    state = {
        userInfo:{},
        isWeixin:true,
        isMobile:true,//判断是否是移动环境
        showCode:'none',
        openDialog:'',
        uname:'',
        authList:[
            {name:'未认证',color:'auth-blue'},
            {name:'个人',color:'auth-yellow'},
            {name:'企业',color:'auth-blue'},
            {name:'组织',color:'auth-green'},
            {name:'公众号',color:'auth-purple'},
        ],
        randomNumber: Math.floor(Math.random() * 3) + 1,
        showGameCode: 'none',
    };  
    componentDidMount(){
        document.title = '个人中心'
        this.setState({
            isWeixin:WxApi.isWeixn(),
            isMobile:WxApi.isMobile()
        })
        this.props.enrollStore.getCurrMenu(3)
        if(!Config.TOKEN){
            React.$wx.webLogin()
            return
        }
        this.getDetail()
        WxApi.configApi()
    };

    render(){
        return (
            <div className="user-center-box">
                 {
                    this.renderDetailQrCode() //小程序二维码弹窗
                }
                {
                    this.renderGameCode()
                }
                <img className='persol-header-bg' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/b6d9a4adbb72d8f7de3e9da5171c8611.png"></img>
                <div className="header-box">
                    <div className="header-left-box">
                        <img src={this.state.userInfo.logo||Config.defaultAvatar} className="user-avatar" onClick={()=>{
                            if(this.state.userInfo.auth_status == 2){
                                sessionStorage.setItem('sourceType','personal_default_auth')
                                this.props.history.push('/auth')
                            }else{
                                React.$wx.jumpNewPath.call(this,'/personalInfo')
                            }                           
                        }}></img>
                        <div className='cursor' onClick={()=>{
                            if(this.state.userInfo.auth_status == 2){
                                sessionStorage.setItem('sourceType','personal_default_auth')
                                this.props.history.push('/auth')
                            }else{
                                React.$wx.jumpNewPath.call(this,'/personalInfo')
                            }                           
                        }}>
                            <div>{WxApi.getDefaultName(this.state.uname)}</div>
                            { 
                                this.getAuthType() 
                            }
                        </div>
                    </div>
                    <div className='header-right-box' onClick={()=>{
                            React.$wx.jumpNewPath.call(this,'/personalInfo')
                        }}>
                        <img className='editor-icon' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20250102/af4239cfa9aeb9d66102a6ed5869dc05.png" />
                        <span>修改</span>
                    </div>
                </div>
                {
                    this.getHeaderBanner()
                }
                <div className="main-box">
                    <ul className="menu-list">
                        <li className='menu-item' onClick={()=>{
                            this.props.history.push('/merchant')
                        }}>
                            <img className="menu-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/18fbb00b5cbf23563884695ab54a5844.png"/>
                            <span>商户钱包</span>
                        </li>                        
                        <li className='menu-item' onClick={()=>{
                                React.$wx.jumpNewPath.call(this,'/h/'+this.state.userInfo.unionid)
                            }}>
                            <img className="menu-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/2a30c0168a4f1f6c8641bcb7a582c493.png" />
                            <span>主页合集</span>
                        </li>
                        <li className='menu-item' onClick={()=>{
                            React.$wx.openWeChat('/pages/personal/personal.html').then(()=>{
                                this.openDialog('block');
                            })
                        }}>
                            <img className="menu-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/add2411ac689c1ae298835ce3c1b90f7.png"/>
                            <span>成员管理</span>
                            { this.state.isWeixin && this.state.isMobile ? this.weixinPage('/pages/personal/personal.html') : ''}
                        </li>
                        <li className='menu-item' onClick={()=>{
                            this.props.history.push('/recycleBin');
                        }}>
                            <img className="menu-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/2025-01-11/2bb60fa7a9c96925f668ed2a04d2e528.png" /> 
                            <span>回收站</span>
                        </li>                        
                    </ul>
                    <ul className="menu-list">
                        <li className='menu-item'  onClick={()=>{
                            sessionStorage.setItem('orderTab', 'first')
                            this.props.history.push('/payOrder')
                        }}>
                            <img className="user-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/00094a6abe6788f02385be8ab56d04b8.png"/>
                            <span>订单</span>
                        </li>
                        <li className='menu-item'  onClick={()=>{
                            this.props.history.push('/my-vipcard')
                        }}>
                            <img className="user-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/e5cfec5d17fcdecdda2efeba2f138398.png" />
                            <span>会员卡</span>
                        </li>
                        <li className='menu-item' onClick={()=>{
                            this.props.history.push({pathname:'/personalInfo/',query:{type:1}})
                        }}>
                            <img className="user-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/0b82d54de1d361aa0794bd1bdc725b1c.png"/>
                            <span>提醒</span>
                        </li>
                        <li className='menu-item' onClick={()=>{
                            this.props.history.push({pathname:'/personalInfo/',query:{type:0}})
                        }}>
                            <img className="user-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20250103/dbe46e8a1898bcb8264e92dc0fd6abf7.png"/> 
                            <span>快速填写</span>                           
                        </li>                      
                    </ul>   
                    <div className='tab-list'>
                        <div className="tab-items" onClick={()=>{
                            this.props.history.push({pathname:'/personalInfo/',query:{type:2}})
                        }}>
                            <img className='help-icon' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20250103/c20c397b8328a18ea42b4c0e9a5e6257.png"/>                          
                            <div className='right-items'>
                                <span className='tab-text'>隐私安全</span>
                                <img src={right_arrow} className='icon-right'/>                     
                            </div>                          
                        </div>                                                      
                        <div className='tab-items' style={{'position':'relative'}} onClick={()=>{                  
                            React.$wx.openWeChat('/other/help/help.html?tab=2').then(()=>{
                                this.openDialog('block');
                            })
                        }}>
                            <img className='help-icon' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/295d6412e0acd5b4f67d959341808204.png" /> 
                            <div className='right-items'>
                                <span className='tab-text'>小程序推荐</span>
                                <img src={right_arrow} className='icon-right'/>                     
                            </div>
                        { this.state.isWeixin && this.state.isMobile ? this.weixinPage('/other/help/help.html?tab=2') : ''}
                        </div>                   
                        <div className='tab-items' onClick={()=>{
                            this.props.history.push('/help')
                        }}>
                            <img className='help-icon' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241231/c78026869c7827b1d11923ea322715aa.png" /> 
                            <div className='right-items'>
                                <span className='tab-text'>帮助中心</span>
                                <img src={right_arrow} className='icon-right'/>
                            </div>
                        </div> 
                        <div className='tab-items' onClick={()=>{
                            window._ZHINI({action: 'showPanel'});
                        }}>
                            <img className='help-icon' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/2025-01-11/f9a257711645b7bf7cfa79bbc322a8e0.png" /> 
                            <div className='right-items'>
                                <span className='tab-text'>反馈建议</span>
                                <img src={right_arrow} className='icon-right'/>
                            </div>
                        </div>  
                    </div>   
                                                        
                </div>
                {
                    this.state.userInfo && this.state.userInfo.auth_status != 2 ? this.renderAdvert() : null //广告位展示
                }   
                {
                    this.state.userInfo && this.state.userInfo.sup_vip == 1 ?
                    <div className='change-login-panel flex-center-box'>
                        <Button className='operate-btn' size='small' type="primary" onClick={()=>{
                            Modal.prompt('用户unionid', '', [
                                { text: '取消' },
                                { text: '确定', onPress: value =>{
                                    if(!value.length){
                                        Toast.info('请输入uniond');                                                
                                    }else{
                                        this.props.myEnrollStore.vipToLogin({unionid: value.trim()}).then(res=>{
                                            if(res.sta == 0){
                                                WxApi.setToken(res.data.access_token);
                                                window.location.reload();                                           
                                            }else{
                                                Toast.fail(res.msg);
                                            }
                                        })
                                    }
                                }},
                            ])
                        }}>用户身份登录</Button>
                        <Button className='operate-btn' size='small' type="primary" onClick={()=>{
                            Toast.loading('切换中')
                            let currProv = 'production';
                            if(localStorage.getItem('currPro') == 'production'){
                                currProv = 'test';
                            }
                          
                            localStorage.setItem('currPro',currProv);                          
                            setTimeout(()=>{
                                Toast.hide()        
                                Config.TOKEN = '';
                                localStorage.removeItem(Config.wx_token_key);
                                let href = window.location.href;
                                let routerArr = href.split('?'); //截取是为了避免多次切换时，链接携带微信code导致未授权登录  	 
                                window.location.href = routerArr[0];                                                                                                     
                            },1000)                      
                        }}>切换为{localStorage.getItem('currPro') == 'test' ? '线上环境':'线下环境'}</Button>
                    </div> : ''
                }            
            </div>
        )
    };
    renderAdvert(){
        const lotteryBtnStyle = {
            position:'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'134px'
        }
        if(this.state.randomNumber == 1){
            return (
                <div className="lottery-panel">
                    <img className="lottery-banner" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241206/ea198caf7758a7a74dc24796ab08e228.png" onClick={()=>{							
                        if(!Config.TOKEN){
                            React.$wx.webLogin();
                        }else{
                            window.open('https://52choujiang.cn');
                        }                                                  
                    }} />
                    {
                        this.state.isWeixin && this.state.isMobile && Config.TOKEN ? <wx-open-launch-weapp
                            style={lotteryBtnStyle}
                            id="launch-btn"
                            username="gh_eb6aa072a31e"
                            path={'/pages/dailylotto/dailylotto.html'}>
                            <script type="text/wxtag-template">
                                <div style={{width:'100%',height:"134px"}}></div>
                            </script>
                        </wx-open-launch-weapp> : ''
                    }
                </div>
            )
        }else if(this.state.randomNumber == 2){
            return (
                <div className="lottery-panel">
                    {/* 答题 */}
                    <img className="lottery-banner" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20240308/1dfa4f1b0498f91cf8043b792cd65c5e.png" onClick={()=>{                                
                        if(!Config.TOKEN){
                            React.$wx.webLogin();
                        }else{
                            window.open('https://xiaoxiaodati.com/');
                        }    
                    }} />
                    {
                        this.state.isWeixin && this.state.isMobile && Config.TOKEN ? <wx-open-launch-weapp
                            style={lotteryBtnStyle}
                            id="launch-btn"
                            username="gh_f208968c610c"								
                            path={'/pages/createdefault/createdefault.html'}>
                            <script type="text/wxtag-template">
                                <div style={{width:'100%',height:"134px"}}></div>
                            </script>
                        </wx-open-launch-weapp> : ''
                    }
                </div>
            )
        }else{
            return (
                <div className="lottery-panel">                           
                    <img className="lottery-banner" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241226/ed30faf62413abb5bbde7dce3890ee17.jpg" onClick={()=>{                                
                        if(!Config.TOKEN){
                            React.$wx.webLogin();
                        }else{
                            this.setState({showGameCode: 'block'}); 
                        }    
                    }} />
                    {
                        this.state.isWeixin && this.state.isMobile && Config.TOKEN ? <wx-open-launch-weapp
                            style={lotteryBtnStyle}
                            id="launch-btn"
                            username="gh_20876a8acedf"								
                            path={'/page/index/index.html'}>
                            <script type="text/wxtag-template">
                                <div style={{width:'100%',height:"134px"}}></div>
                            </script>
                        </wx-open-launch-weapp> : ''
                    }
                </div>
            )
        }
    };
    //查看立吉 2048小程序二维码
    renderGameCode(){
        return(
            <Flex className="Detail-minProgram-panel" style={{display:this.state.showGameCode}}>
                <div className="share-choice-mask" onClick={() => {this.setState({showGameCode:'none'})}}></div>
                <div className="minProgram-panel">
                    <div className="new-share-minImg">
                        <i className="close-btn" onClick={() => {this.setState({showGameCode:'none'})}}></i>
                        <div className="panel-title">请去「立吉 2048」小程序查看</div>
                        <div className="QRCode-img">
                            <img src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241226/bb42e3cf2f2821fd3258fac055e1062b.jpg" />
                        </div>
                        <div className="min-tips">长按识别二维码，查看活动详情</div>
                    </div>
                </div>
            </Flex>
        )
    };
    weixinPage(pathName){
        const miniBtnStyle = {
			width: '100%',
		    height:'100%',	 
			color: 'transparent',
			fontSize:12,
			textAlign:'center',
            position:'absolute',
            left: 0,    
            top: 0,    
            zIndex: 10,   
		}
        return (           
            <wx-open-launch-weapp style={miniBtnStyle}
                id="launch-btn"
                username="gh_ae6449501a82"
                path={pathName}
                >
                <script type="text/wxtag-template">
                    <div style={miniBtnStyle}>打开小程序</div>
                </script>
            </wx-open-launch-weapp>             
        )
    }

    //查看小程序二维码
	renderDetailQrCode(){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.showCode}}>
				<div className="share-choice-mask" onClick={() => { this.openDialog('none')}}></div>
				<div className="minProgram-panel">
				<div className="new-share-minImg">
					<i className="close-btn" onClick={() => { this.openDialog('none')}}></i>
					<div className="panel-title">请去「报名工具」小程序查看</div>
					<div className="QRCode-img">
						<img src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20231206/d81b126ce148d513c32db16f97c7e5ea.jpeg'/>
					</div>
					<div className="min-tips">长按识别二维码，立即体验</div>
					</div>
				</div>
			</Flex>
		)
	}

    openDialog(type){
        this.setState({
            showCode: type,
        })
    }

    getDetail(){
        this.props.enrollStore.getUserInfo().then(res=>{
            let {userInfo} = this.props.enrollStore
            let uname = userInfo.uname  
            sessionStorage.setItem('unionid',userInfo.unionid)
            this.setState({
                userInfo,
                uname,
            })
        })
    };

    /* 用户详情auth_type: 0未认证，1个人认证，2企业认证， 3组织认证, 4公众号认证
     auth_status： 0未认证，1进行中，2已认证, 3认证失败, 4认证过期 */
    getAuthType(){
        if(!this.state.userInfo.auth_status)return
        let {auth_status,auth_type,expire,renew_status} = this.state.userInfo
        let isExpired = false
        if (expire) {
            let nowDate = new Date(new Date().toLocaleDateString()).getTime()
            let endTimer = new Date(new Date(expire).toLocaleDateString()).getTime()
            let diffTime = endTimer - nowDate
            let diffDay = diffTime/1000/60/60/24
            if (diffDay < 7) {
                isExpired = true;
            }
        }
        let msg = ''
        if(auth_status == 1){
            msg = <div className='user-auth flex-start-box auth-blue'>
                    <img className="icon-auth" src={icon_identity_going}/>未认证
                  </div>
        }else if(auth_status == 2){
            msg = <div className={this.state.authList[auth_type].color+' user-auth flex-start-box'}>
                    <img className="icon-auth" src={icon_auth}/>{this.state.authList[auth_type].name}{isExpired ? '/ 续费' : ''} >
                  </div>
        }else if(auth_status == 3){
            msg = <div className='user-auth flex-start-box auth-red'>
                    <img className="icon-auth" src={icon_identity_no}/>认证失败
                  </div>
        }else if(auth_status == 4 && renew_status == 2){
            msg = <div className='user-auth flex-start-box auth-blue'>
                    <img className="icon-auth" src={icon_identity_going}/>审核中
                </div>
        }else if(auth_status == 4){
            msg = <div className='user-auth flex-start-box auth-red'>
                     <img className="icon-auth" src={icon_identity_no}/>已过期{isExpired ? '/ 续费' : ''} >
                  </div>
        }
        return  msg         
    };

    getHeaderBanner(){
        if(JSON.stringify(this.state.userInfo) == '{}')return
        let {auth_status,auth_type,expire,auth_time} = this.state.userInfo
        let msg = ''
        if(auth_status == 2){//认证成功          
            let percent = 0, now = new Date().getTime()/1000;
            let expire_time = new Date(expire).getTime()/1000        
            if (now > expire_time) {
              percent = 178              
            } else {      
                let auth_date_dif = (expire_time - auth_time)/60/60/24          
                let now_dif = (expire_time - now)/60/60/24
                percent = ((auth_date_dif - now_dif)/auth_date_dif * 100).toFixed(2)*1.78    
            }
            msg = <div className='auth-status-box' onClick={()=>{
                    sessionStorage.setItem('sourceType','personal_default_auth')
                    this.props.history.push('/auth')
                }}>
                <div className='left-box'>
                    <span>拥有10+项权益，{expire}到期</span>
                    <div className="progress-box"><i className='progress-items' style={{width:percent+'px'}}></i></div>
                </div>
                <button className="auth-btn">立即续费</button>
            </div>
        }else if(auth_status == 4){//认证失败
            msg = <div className='auth-status-box' onClick={()=>{
                    sessionStorage.setItem('sourceType','personal_default_auth')
                    this.props.history.push('/auth')
                }}>
                    <div className='left-box'>您的VIP已过期，不再享有多项VIP权益</div>
                    <button className="auth-btn">立即续费</button>
                </div>
        }else{//未认证
            msg = <div className='no-auth-panel' onClick={()=>{
                    sessionStorage.setItem('sourceType','personal_default_auth')
                    this.props.history.push('/auth')
                }}>
                    <div className='left-box'>
                        <img className='auth-img' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20240327/cf87d67c885026a1fe70cbac5b500226.png"/>
                        <div className='auth-info-box'>
                            <h3 className='auth-title'>VIP认证</h3>
                            <span className='auth-desc'>10+项限时专享权益</span>
                        </div>                       
                    </div>
                    <button className='no-auth-btn'>立即开通</button>
                </div>     
        }
        
       return msg
    }
}

export default userCenter